@font-face {
    font-family: 'tpa-pixel-narrow';
    src: url('./Assets/Fonts/tpa-pixel-narrow.ttf');
  }
  
.menu-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 9999;
    justify-content: flex-start;
    align-items: baseline;
  }

  .menu-container {
    position: relative;
    /* Other styles */
  }

.top-hat-menu {
    transform: scale(3);
    margin-left: 130px;
    margin-top: 30px;
    z-index: 9999;
  }

.twitter-logo {
  transform: scale(1);
  position: absolute;
  left: calc(100% - 145px);
  top: 10px;
}

.connect-button {
  background-color: #4caf50;
  color: #fff;
  cursor: pointer;
  margin-left: auto;
  margin-right: 1%;
  margin-top: -65px;
  font-size: 15px;
}

@media (max-width: 700px) {
  .twitter-logo {
    left: calc(100% - 110px);
    top: 3px;
  }
  .top-hat-menu {
    transform: scale(2);
    margin-left: 69px;
    margin-top: 18px;
  }
  .menu-bar {
    height: 30px;
  }
  .connect-button {
    font-size: 12px;
    margin-top: -59px;
  }
}
  

body {
    background-color: black;
    color: white;
    font-family: 'tpa-pixel-narrow';
    display: flex;
    image-rendering: pixelated;
    margin-left: 5px;
}

.punkbuilder-container{
    position: fixed;
    display: inline;
    justify-content: flex-start;
    top: 43px;
    left: 0px;
    background-color: white;
    width: calc(100vw - 19px);
    min-width: 340px;
    max-width: 690px;
    margin-left: 7px;
    border: 3px solid fuchsia;
    border-radius: 5px;
    z-index: 9998;
}
@media (max-width: 700px) {
  .punkbuilder-container{
    top: 33px;
  }
}

.container-header{
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    overflow: hidden;
    color: fuchsia;
    background-color:white;
    font-size: clamp(8px, 2vw, 18px);
}

.container-header h1{
  margin-left: 2%; /* Set the desired left margin */
}

.container-footer {
    display: flex;
    padding: 1%;
    color:fuchsia;
    background-color:none;
    font-size: clamp(5px, 1vw, 9px);
}

.container-footer option {
    font-size: 800%;
}

#outputSize {
  font-family: 'tpa-pixel-narrow';
  font-size: clamp(10px, 2vw, 15px);
}
  
.container-footer button.download-button {
    background-color: fuchsia;
    color: white;
    margin-left: 1%;
    font-size: 90%;
}
  
.container-footer button.button-shading {
    background-color: green;
    color: white;
    margin-left: 1%;
    font-size: 160%;
}

.container-footer button.button-rules {
    background-color: blue;
    color: white;
    margin-left: 1%;
    font-size: 160%;
}

.container-footer button.button-OG {
    background-color: black;
    color: white;
    margin-left: 1%;
    font-size: 160%;
}

.container-footer button.button-OG.active {
  /* Styles when 'All Traits' is selected */
  background-color: #638596;
  color: white;
}

  
.container-footer button.reset-button {
    margin-left: auto;
    background-color: crimson;
    color: white;
    font-size: 90%;
}

.container-main {
    display: flex;
}

.punk-canvas {
    aspect-ratio: 1/1;
    height: 50%;
    width: 50%;
}

.punk-canvas-container{
    justify-content: center;
    align-items: center;
    margin-left: 4%;
    height:95%;
    width:95%;
    border: 3px solid fuchsia;
    border-radius: 5px;
    flex: 1;
    position: relative;
    color: fuchsia;
}

.punk-canvas-container Canvas {
    max-width: 100%;
    max-height: 100%;
  }

.overlay-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

#unshadedCanvas {
    position: absolute;
    top: 1;
    left: 20;
    z-index: 1;
    width: 30%;
    height: 60%;
  }

.traits-info {
    width: 100%;
    background-color: none;
    color: black;
    padding: 1%;
    margin-top: -15px;
    margin-left: 15px;
    font-size: clamp(8px, 2.5vw, 15px);
    vertical-align: top;
}

.traits-info ul{
    margin-top: -10px;
    margin-left: -10px;
    font-size: clamp(8px, 2.5vw, 16px);
}

.gap {
  margin-top: 55%;

  @media (min-width: 700px) {
    margin-top: 420px;
  }

  @media (max-width: 600px) {
    margin-top: 57%;
  }

  @media (max-width: 450px) {
    margin-top: 59%;
  }

  @media (max-width: 390px) {
    /* Styles for screens up to 390px wide */
    margin-top: 62%;
  }
}

.trait-selection-section{
    margin-top: 0px;
}

.trait-selection-section img {
    border: 1px solid white;
    width: 69px;
    height: 69px;
    margin: 10px;
    margin-left: 10px;
    background-color: #638596;
    border-radius: 5px;
}

@media (max-width: 480px) {
    .trait-selection-section img {
      width: 50px; /* Adjust the width as per your needs */
      height: 50px; /* Adjust the height as per your needs */
      margin: 2px;
      margin-left: 4px;
      margin-bottom: 0px;
    }
  }

button {
    cursor: pointer;
    font-family: 'tpa-pixel-narrow';
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.section-header {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: crimson; /* Set the desired background color */
    height:20px;
    font-size: clamp(8px, 2vw, 14px);
    width: calc(100vw - 10px);
    border-radius: 5px;
    margin-bottom: 5px;
}

.section-header-animation {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green; /* Set the desired background color */
  height:20px;
  font-size: clamp(8px, 2vw, 14px);
  width: calc(100vw - 10px);
  border-radius: 5px;
  margin-bottom: 5px;
}

.popup-overlay {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 25%;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items: flex-end;
    pointer-events: none;
    z-index: 9998;
  }
  
  @media (max-width: 550px) { /* Adjust the breakpoint value as needed */
    .popup-overlay {
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
  
  .popup-overlay img {
    transform: scale(2);
    image-rendering: pixelated;
    padding-bottom: 14px;
  }

  @media (min-width: 550px) {
    .popup-overlay img {
      transform: scale(3);
    }
  }
  
  
  .trait-image-container {
    position: relative;
    display: inline-block;
  }
  
  .overlay {
    position: absolute;
    white-space: nowrap;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 5px;
    font-size: clamp(8px, 2.5vw, 14px);
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    pointer-events: none; /* Make the caption text click-through */
  }
  
  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .flashing-text {
    animation: flash 0.4s 4;
  }

  .matchpunk-comment {
    color: green;
    font-size: clamp(10px, 2.5vw, 17px);
  }
  
  @media screen and (max-width: 500px) {
    .matchpunk-comment {
      font-size: 2.5vw;
    }
  }

  .overlayMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.69);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .overlayMenu-content {
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    border: 5px solid crimson;
    border-radius: 5px;
    width: 75%;
    padding: 0px 20px;
    text-align: left;
  }

  .overlayMenu-content h1{
    margin-top: 1rem;
  }

  .overlayMenu-content h3{
    font-family: Verdana, sans-serif;
    text-align: center;
  }

  .overlayMenu-content h5{
    font-family: Verdana, sans-serif;
  }

  .overlayMenu-content h6{
    font-family: Verdana, sans-serif;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .version-highlight {
    color: fuchsia;
  }

  .tip-hat-gif-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tip-hat-gif{
    transform: scale(2);
    margin-bottom: 1.5rem;
  }

  .blank-line {
    margin-bottom: 50px; /* Adjust the value as needed */
  }

  .clickable-area { 
    cursor: pointer; /* this is the clickable area of the menu options*/
  }
  
  @media (min-width: 630px) {
    .overlayMenu-content {
      font-size: 20px;
    }
    
}

.animation-section {
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 400px; /* Set a fixed height or adjust as needed */
}

.animation-section img {
  border: 1px solid white;
  width: 69px;
  height: 69px;
  margin: 10px;
  margin-left: 10px;
  background-color: #638596;
  border-radius: 5px;
}
  
.tap-the-hat-gif{
  transform: scale(2);
  margin-top: 30px;
}